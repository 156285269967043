import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Image = ({ filename, alt, className }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile {
            edges {
              node {
                relativePath
                childImageSharp {
                  gatsbyImageData(quality: 100, placeholder: NONE)
                }
              }
            }
          }
        }
      `}
      render={data => {
        const image = data.allFile.edges.find((n) =>
          n.node.relativePath.includes(filename)
        );
        return <GatsbyImage className={className} image={getImage(image.node.childImageSharp)} alt={alt} />;
      }}
    />
  );
};

export default Image;
