import React from "react";
import Image from "../image/image.js";
// import ReadMore from "../readMore/readMore.js";
import PurpleCheck from "../../assets/img/svg/check.svg";
import { Helmet } from "react-helmet";

import "./style.scss";

const Header = ({ title, description, logo, metaDescription, banner, alt, tagline, body, list }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title} | Eucleia - Migliora la tua reputazione online!</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content="Eucleia - Migliora la tua reputazione online!" />
        <meta property="og:image" content="https://static.acmeproduzioni.it/eucleia/logo.svg" />
        <meta property="og:description" content="Migliora la tua reputazione online"/>
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      {banner ? (
        <div className="header">
          <div className="container">
            <div className="columns ">
              <div className="column is-fullhd">
                <div className="title">
                  <h1>{tagline}</h1>
                </div>
                {list ? (
                  <ul>
                    {list.map((i, index) => (
                      <li key={index}><img src={PurpleCheck} alt="Purple Check Mark" />&nbsp;&nbsp;{i}</li>
                    ))}
                  </ul>
                ) : (
                  body && <p>{body}</p>
                )}
                {/* <ReadMore to="/" text="Scopri di più" color="white" /> */}
              </div>
              <div className="column is-fullhd">
                <Image className="imgSlider" filename={banner} alt={alt} />
                <Image className="floatI" filename="ball.png" alt="ball" />
                <Image className="floatJ" filename="star.png" alt="star" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header">
          {/* <div className="section" /> */}
          <div className="container">
            <div className="columns">
              <div className="column ">
                <div className="title">
                  <h1>{title}</h1>
                </div>
                {description && (
                  <div className="columns mt-6">
                    <div className="column is-half is-offset-one-quarter ">
                      <p className="center">
                        <br />
                        {description}
                      </p>
                    </div>
                  </div>
                )}
                {!logo && <div className="section" />}
              </div>
            </div>
          </div>
          {logo && (
            <>
              <div className="columns">
                <div className="column is-4 is-offset-4">
                  <div className="section" />
                  <Image filename={logo} alt={title} />
                  <div className="section" />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Header;
